<template>
<!-- 赛事-今年全市赛事数量排名 -->
    <div class="baixing darkBack">
        <div class="baixingnav">
            <div>今年全市赛事数量排名</div>
            <div>
                <!-- <span class="selecte" id="selected" @click="selectedhandle">月</span> -->
                <span class="selecte1" @click="selectedhandle1">月</span>
                <span class="selecte1" @click="selectedhandle1">年</span>
                <span class="selecte1" @click="selectedhandle1">总</span>
            </div>
        </div>
        <div id="matchRank" style="width:5.50rem; height:3.80rem;margin-top:0.2rem;"></div>
    </div>
</template>

<script>
import bus from "../../../assets/js/bus";
 import {getAjax, timestamp} from "/src/assets/js/websocket";

export default {
    data () {
        return {
            sport:{
                data: ["新昌县", "诸暨市", "柯桥区", "越城区", "上虞区", "嵊州市"],
                number:["521", "421", "367", "326", "320", "272"],
                max: 5,
            },
        }
    },
    methods: {
        // 切换时间
        selectedhandle1(e){ 
            let that = this;         
            // $('.selecte').class('background', "#000000")
            $('.selecte1').css({'background-color':'#00000000', 'color':'#838181'})
            e.target.style.background = "#7EA7E2";
            e.target.style.color = "#000000";
            // 切换柱状图表
            var switchover = e.target.innerText;

            let list;
            var data = {
                    token: window.getToken(),
                    app_id: window.appId,
                    time: timestamp(),
                }
            getAjax({
                url: "/map/screen/getMatchAll?ranking="+e.target.innerText,
                method:"GET",
                data:data,
                success:function(res){
                    // console.log(res)
                    that.sport = {
                        data: [],
                        number:[],
                        max: 0,
                    }
                    list = res.data.area_data;
                    list.forEach((item,index)=>{
                        that.sport.data.push(item.name);
                        that.sport.number.push(item.match_num);
                    })
                    that.sport.max = list.length;
                    that.fitness("matchRank", that.sport)
                },
            })
        },
        // 柱状图
        fitness(id, number){
            let that = this;
            // console.log(number)
            // let canye = that.$echarts.init(document.getElementById(id));
            let canye = this.$echarts.getInstanceByDom(document.getElementById(id))
            if (canye == null) { // 如果不存在，就进行初始化
                canye = this.$echarts.init(document.getElementById(id));
            }
            // var data = number.number
            // var data = [];
            // for (let i = 0; i < 6; ++i) {
            //     data.push(Math.round(Math.random() * 200));
            // }

            let option = {
                 xAxis: {
                    // max: 'dataMax',
                    show:true,
                    type: 'value',
                    splitNumber: 6,
                    "axisLine":{     //x轴坐标轴
                        "show":false,
                    },
                    axisLabel:{
                        show: false,
                    },
                    
                    axisTick: {		//x轴刻度线
                        show:false
                    },
                    splitLine: {            // 网格线的颜色
                        show: true,
                        lineStyle:{
                            color: '#2D94D040',
                            width: 1,
                            type: 'solid',
                        }
                　　}
                },
                grid: {         // 间距
                    left: '8%',  
                    right: '4%', 
                    top:'4%', 
                    bottom: '4%',  
                    containLabel: true,
                },
                yAxis: {
                    type: 'category',
                    data: number.data,
                    inverse: true,              // 上下反向
                    // animationDuration: 300,
                    // animationDurationUpdate: 300,
                    max: number.max,          // y轴显示显示最大的条数 
                    axisLine:{              // y轴左侧的样式
                        show:true,
                        lineStyle: {
                            color: '#2D94D0',
                        }
                    },
                    splitLine:{show: false},
                    axisTick: {                 // y轴刻度线
                        show:false,
                        alignWithLabel: true,
                    },
                    splitArea : {show : false}, 
                    axisLabel: {               // y轴字体的颜色
                        // margin: 176,//刻度标签与轴线之间的距离
                        interval:0,//横轴信息全部显示 
                        textStyle: {
                            show:true,
                            fontFamily:'微软雅黑',
                            color: "#7EA7E2",
                            fontSize: '0.13rem',
                            // align: 'left'
                        },                           
                    },
                },
                series: [{
                    // realtimeSort: true,
                    // name: 'X',
                    type: 'bar',
                    barWidth : '40%',              // 柱子的宽度
                    data: number.number,
                    itemStyle: {
                        normal: {
                            color: that.$echarts.graphic.LinearGradient(0, 0 , 1, 0, [{
                                offset: 0,
                                color: "#2D94D0" // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: "#3AE6CE" // 100% 处的颜色
                            }], false)
                        }
                    },
                    label: {
                        formatter: "{c}",
                        show: true,
                        position: 'right',
                        valueAnimation: true,
                        color: '#3AE6CE',    // 柱状图上字体的颜色
                        fontSize:'0.13rem',
                    },
                }],
                legend: {
                    show: true
                },
                animationDuration: 0,
                animationDurationUpdate: 3000,
                animationEasing: 'linear',
                animationEasingUpdate: 'linear'
            };
            // 使用刚指定的配置项和数据显示图表。
            canye.setOption(option);
            // 图表自动缩放
            window.addEventListener("resize",function(){
          	    canye.resize();
            });
        }
    },
    mounted () {
        // this.fitness("matchRank", this.sport)
    },
    created () {
        let that = this;
        bus.$on('num_ranking',res=>{
            // console.log(res)
            that.sport = {
                data: [],
                number:[],
                max: 0,
            }
            res.forEach((item,index)=>{
                that.sport.data.push(item.name);
                that.sport.number.push(item.match_num);
            })
            that.sport.max = res.length;
            // console.log(that.sport)
            that.fitness("matchRank", that.sport)
        })
    },
    beforeCreate(){
        bus.$off('num_ranking')
    }
}
</script>

<style scoped>
.baixing{
    width:5.96rem;
    height:5.2rem;
    font-size: 0.20rem;
    color:#fff;
    padding: 0.2rem;
    position: relative;
}



.baixingnav{
    display: flex;
    justify-content: space-between;
}
.selecte1{
    width:33%;
    text-align: center;
    height:0.22rem;
    line-height: 0.22rem;
    display: inline-block;
    font-size: 0.12rem;
    cursor: pointer;
    color:#838181;
}

.selecte1:nth-child(1){
    /* background-color:#7EA7E2; */
    border-radius: 0.11rem 0rem 0rem 0.11rem;
    /* color:#000000; */
}
.selecte1:nth-child(2){
    /* background:red; */
    border-left:1px solid #838181;
    border-right:1px solid #838181;
}
.selecte1:nth-child(3){
    /* background:red; */
    background-color:#7EA7E2;
    color:#000000;
    border-radius: 0rem 0.11rem 0.11rem 0rem;
}


.baixingnav>div:nth-child(2){
    display: flex;
    justify-content: space-around;
    width:1.05rem;
    height:0.22rem;
    border:0.01rem solid #838181;
    border-radius: 0.11rem;
}

</style>